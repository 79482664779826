.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.facility-logo {
			position: absolute;
			left: 0px;
			top: 198px;
			width: 400px;
			padding-left: 5vw;
			z-index: 5;

			@media (min-width: 1199px) {
				top: 175px;
			}

			@media (max-width: 575px) {
				display: none;
			}

			.logo {

				img {

					&.polder {
						margin-top: -2%;
						margin-left: -10px;
						width: 256px;
					}

					&.boomgarden {
						margin-top: -2%;
						margin-left: -10px;
						width: 320px;
					}

					&.canvas {
						margin-top: -2%;
						margin-left: -5px;
						width: 176px;
					}

					&.dreybuelt {
						margin-top: -4%;
						margin-left: -1px;
						width: 187px;
					}

					&.nordsee {
						margin-top: -1%;
						margin-left: -1px;
						width: 330px;
					}

				}
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			padding-top: var(--page-margin-top);

			@media (max-height: 600px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 55vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 992px) {
			top: 50vh;
		}

		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 5vw;
				color: var(--color-white);
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 80px;
				}

				@media (max-width: 1140px) {
					font-size: 57px;
				}

				@media (max-width: 992px) {
					font-size: 4rem;
					line-height: 4rem;
				}

				@media (max-width: 576px) {
					font-size: 3rem;
					line-height: 3rem;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}