.contact-form-v1 {
	background-color: var(--contact-sidebar-bg-color);

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}