.blogentry-v1 {
	padding-top: var(--page-margin-top);
	padding-bottom: var(--page-padding-bottom);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}
}