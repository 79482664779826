.sozial-media {
	background-color: var(--color-primary);
	padding: 40px 0px;

	color: var(--color-white);


	.title {
		color: var(--color-white);
		font-family: 'Marydale';
		font-size: 30px;
		padding: 40px 0 70px;
	}


	.sozial-item {
		display: inline-block;
		width: 30%;
		text-align: center;
		margin: 15px 0;
		vertical-align: top;

		@media (max-width: 660px) {
			width: 40%;
			padding-top: 20px;
		}

	}



	.logo {
		position: relative;

		img {
			width: 140px;
		}

		.polder {
			padding-bottom: 10px;
			width: 140px;

			@media (max-width: 768px) {
				width: 105px;
			}
		}

		.canvas {
			width: 127px;

			@media (max-width: 768px) {
				width: 90px;
			}
		}

		.boomgarden {
			padding-bottom: 15px;
			width: 250px;
			max-height: 120px;

			@media (max-width: 768px) {
				width: 127px;
			}
		}

		.logierhaus {
			padding-bottom: 15px;
			width: 97px;
		}

		.dreybuelt {
			width: 127px;

			@media (max-width: 768px) {
				width: 90px;
			}

		}
	}

	img {
		filter: brightness(0) invert(1);
	}




	.icon {
		text-align: center;

		padding-top: 20px;
		height: 55px;
		display: block;

		.fa {
			border: 1px solid #fff;
			border-radius: 5px;
			line-height: 34px;
			width: 40px;

			@media (max-width: 768px) {
				line-height: 28px;
				width: 34px;
			}


			&.fa-instagram {
				margin-left: 5px;
			}
		}
	}


	a {
		color: var(--color-white);
		text-decoration: none;
		font-size: 1.1rem;
		letter-spacing: 1px;

		&:hover {
			text-decoration: underline;
		}
	}



}