.parallax-v2 {
	position: relative;
	background-image: url(RESOURCE/img/polder_sanierung_022-1.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		background-color: var(--color-primary);
		padding: 20px;
		min-height: 400px;

		.title {
			color: var(--color-primary-light);
			font-family: 'Marydale';

			.h2 {
				@media (min-width:1500px) {
					font-size: 1.8rem;
				}
			}

		}

		.text {
			color: var(--color-primary-light);

			a {
				color: var(--color-white);
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

}