.anlagen {
	.cards {
		margin-top: 30px;
		padding: 20px;
		transition: 0.3s;

		&:hover {
			box-shadow: 0 0px 0px 1px rgb(0 102 157);
			/*box-shadow: 0 0px 10px rgb(0 0 0 / 50%) */
		}
	}

	.title {
		font-family: 'Marydale';
		font-size: 18px;
		color: var(--color-white);
		text-align: center;
		width: 240px;
		right: -10px;
		top: 15px;
		line-height: 38px;
		position: absolute;
		background-color: var(--color-primary);
	}


	.image {
		position: relative;
		max-height: 308px;

		img {
			width: 100%;
			height: auto;

		}

		.facility-offer {

			font-family: Marydale;
			position: absolute;
			bottom: 10px;
			-webkit-transform: rotate(19deg);
			transform: rotate(0deg);
			font-size: 18px;
			margin: auto 0;
			text-align: center;
			width: 100%;
			color: #FFF;

			@media(min-width: 1200px) {
				font-family: Marydale;
				position: absolute;
				bottom: 15px;
				right: 15px;
				-webkit-transform: rotate(19deg);
				transform: rotate(19deg);
				width: 140px;
				font-size: 18px;
			}

			.offer-text {

				&.boomgarden {
					background: rgba(0, 0, 0, .3);
				}
			}

		}

		.over-text {
			position: absolute;
			bottom: 0;
			text-align: center;
			background: rgb(0 0 0 / 60%);
			width: 100%;
			padding: 15px;
			font-size: 25px;
			text-align: center;
			color: #fff;
			font-family: Marydale;

			@media (max-width: 991px) {
				padding: 10px 15px;
				font-size: 18px;
			}
		}
	}

	.logo {
		background-color: var(--color-white);
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 140px;

		img {
			width: 200px;
			max-height: 160px;

			&.logo-stad {
				width: 90%;
			}



		}


	}


}