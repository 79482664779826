.searchbar-v1 {
	position: absolute;
	top: 74vh;
	width: 100%;
	padding: 0px 30px;

	@media (max-width: 992px) {
		top: 65vh;
	}

	@media (max-width: 767px) {
		padding: 0 30px;
	}

	@media (max-height: 600px) {
		top: 350px;

	}

	.searchbar-container {
		padding-left: 0;
		padding-right: 0;
		max-width: 1300px;
		width: 100%;
		margin: 0 auto;
	}

	.title {
		color: var(--color-primary);
		background-color: var(--color-secondary-light);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;

		@media (max-width: 767px) {
			padding: 10px 14px;
		}

	}

	.tabs {
		padding-left: 15px;
		padding-right: 15px;
	}

	.navbar-tabs {
		background-color: var(--color-secondary-light);
		border-radius-top-left: 10px;
		border-radius-top-right: 10px;
		cursor: pointer;

		&.active {
			background-color: var(--color-primary);
			color: #fff;
		}

	}

	#searchformobj {
		display: none;

		.btn {
			.fa {
				padding-top: 2px;
			}


		}


		/*	.searchobj {
			padding: 21px 10px;
			background-color: var(--color-white);
			width: 100%;

			.form-control {
				padding: 30px 10px;
				border: none;
				padding-left: 10px;

				::placeholder {
					color: blue;
				}
			}

			.form-control::placeholder {
				font-family: 'Open Sans';
			}

			.btn {
				width: 102px;
				font-size: 28px;

				.fa {
					padding-left: 2px;
				}


			}
		} */
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 1px solid #eaeaea;
		border-top: 0px solid var(--color-primary);
		padding-left: 12px;
		flex-flow: wrap;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/

		.form-flex {
			width: 30%;
			padding: 7px 20px;
			border-right: 1px solid #f4f4f4;

			&.form-flex-100 {
				width: 90%;
				border-right: 1px solid #f4f4f4;
				padding-top: 32px;
			}

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 0;
				top: 11px;
			}

			.form-control {
				padding: 5px 0;
				background-color: var(--white);
				border: none;
				padding-right: 30px;
				background-color: transparent;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				min-height: 40px !important;
				height: auto;
			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
			}

			.form-flex-100 {
				width: 90%;
				padding: 20px 22px;
				border-right: 1px solid #f4f4f4;

			}

		}

		.searchbox {
			@media(min-width:993px) {
				float: right;
				padding-top: 7px;
				padding-bottom: 7px;
				margin-right: -1px;
			}
		}



		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding-bottom: 5px;
			}

			.btn {
				width: 102px;
				height: 60px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (min-width:992px) {
					line-height: 49px;
				}


				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}