.unit-card-v2 {
	a {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.cards {
		padding: 15px 15px;
		transition: 0.3s;

		&:hover {
			box-shadow: 0 0px 0px 1px rgb(0 102 157);
		}



		.content {
			position: relative;
			padding: 10px;
			display: flex;
			flex-direction: column;
			min-height: 170px;
			justify-content: space-between;
			background-color: var(--color-white);

			.h5 {
				padding-top: 5px;
				color: var(--font-color-main);
				font-weight: 700;
			}

			.facility-image {
				position: absolute;
				right: 0px;
				top: 40px;

				img {
					width: 120px;
					height: auto;
				}
			}

			.title {
				max-width: 90%;
			}

			.region-type {
				font-size: var(--font-size-md);
			}

			.fa {
				padding-right: 5px;


			}

			.fa-stack {
				height: 33px !important;
				margin-left: -5px;
				width: 24px !important;
			}



			.fa-map-marker-alt {
				padding-left: -10px;
			}

			.label-container {
				display: flex;
				justify-content: space-between;
				flex-direction: row-reverse;

				.label {
					padding: 5px 10px;
					color: white;
					border-radius: 5px;
					font-weight: 500;
					width: fit-content;
					font-weight: 500;
					line-height: 25px;
					font-size: var(--font-size-md);
				}

				.btn {
					font-size: var(--font-size-md);
				}

				.customer-rating {
					background: var(--color-secondary);
					font-weight: 500;
				}
			}

			.favorite {
				position: absolute;
				top: 10px;
				right: 10px;
				color: var(--font-color-main);
				font-size: 1.5em;
				z-index: 5;
			}


		}

		.rules {
			text-align: right;
			padding: 5px;
			color: var(--font-color-main);
		}

		.comodities {}


		.comodity {
			padding: 5px;
		}

		.image-wrap {
			position: relative;

			/*	&:hover {
				img {
					filter: brightness(0.7);
				}
			} */

			img {
				width: 100%;
				transition: 0.5s;

			}

			.stars {
				position: absolute;
				bottom: 10px;
				right: 10px;
				color: var(--color-stars);
				background-color: white;
				padding: 9px;
				border-radius: 5px;
				display: flex;
				align-items: center;
			}



			.region-banner {
				position: absolute;
				top: 10px;
				right: -10px;
				color: white;
				background-color: var(--color-primary);
				font-family: 'Marydale';
				font-size: 20px;
				line-height: 30px;
				width: 240px;
				text-align: center;

			}
		}


		.ribbon {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			overflow: hidden;
			width: 75px;
			height: 75px;
			text-align: right;
		}

		.ribbon span {
			font-size: 13px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			line-height: 20px;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			width: 100px;
			display: block;
			background: var(--color-red);
			box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
			position: absolute;
			top: 19px;
			left: -21px;
		}
	}
}