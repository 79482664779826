.blog-card-v1 {
	margin-bottom: 20px;
	width: 100%;
	/*box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);*/
	background-color: var(--color-white);
	padding: 10px;
	position: relative;
	transition: 0.3s;

	&:hover {
		box-shadow: 0 0px 0px 1px rgb(0 102 157);
	}

	.image {
		height: 320px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.blog-body {
		padding-top: 10px;
	}

	.date {
		position: absolute;
		font-size: 0.85rem;
		background-color: var(--color-primary);
		padding: 2px 5px;
		font-family: Marydale;
		color: var(--color-white);
		top: 20px;
		right: -10px;
		z-index: 2;

	}

	.title {
		font-size: var(--blog-card-title-font-size);
		color: var(--blog-card-title-font-color);
		min-height: 60px;
		font-weight: 400;
		letter-spacing: 1px;
	}

	.link {
		text-align: right;
	}
}