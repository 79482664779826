.contact-page-layout-v1 {
	padding-top: var(--page-margin-top);
	padding-bottom: var(--page-padding-bottom);

	.heading1 {
		padding-bottom: 40px;
	}

	.kontakt-text {
		font-size: 20px;
	}
}