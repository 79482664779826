.contact-sidebar-v1 {
	background-color: var(--contact-sidebar-bg-color);

	.contact-map {
		width: 100%;
		height: 300px;
	}

	.contact-bl {
		/*	display: flex;*/
		margin-bottom: 15px;
		position: relative;
		padding-left: 40px;
		min-height: 40px;
		align-items: center;
		font-size: var(--font-size-md);
		/*flex-direction: column;
		align-items: baseline;*/

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-primary);
			}
		}


		i {
			color: var(--color-white);
			background-color: var(--color-primary);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			font-size: 16px;
		}


	}

	.openings {
		div {
			font-size: var(--font-size-md);
		}

		.office-day {
			display: inline-block;
			width: 100px;
		}

		.office-time {
			display: inline-block;
		}
	}
}