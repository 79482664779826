.empfehlungen {
	position: relative;
	background-image: url(RESOURCE/img/bg_empfehlungen3.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.heading1 {
		padding-top: 70px;
	}

	.blog-card-v1 {
		border: none;
	}



}