.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);



	.left-col {
		padding: 0px 15px;


		@media(max-width: 1200px) {
			width: 100% !important;
		}


		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}


	.right-col {

		padding: 0px 15px;


		@media(max-width: 1200px) {
			display: none;
		}


		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}

	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	#book-now {
		top: 200px;

		&.book-date-selected {
			display: none !important;

			&.is-visible {
				@media(min-width:992px) {
					display: block !important;
				}
			}
		}
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 208px;

			margin-bottom: 57px;
		}

		&.is-visible {
			.btn-cal {
				@media(min-width:992px) {
					display: block;
				}
			}

		}

		.btn-cal {
			display: none;
		}

	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}


	}

	.unit-book-now-v2-main {
		@media all and (min-width: 992px) and (max-width: 1200px) {
			display: block !important;
		}
	}

	.Preisemodal {
		h4 {
			text-align: center;
			width: 100%;
		}

		.modal-body {
			height: 70vh;
			overflow: auto;
		}

		.widget-header {
			display: none;
		}
	}

	.dlg {
		.layout-column {
			height: auto;

			.close {
				position: absolute;
				top: 10px;
				right: 20px;
			}

			.body.flex {
				padding: 22px 15px;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				position: relative;
				-webkit-box-flex: 1;
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				padding: 1rem;
			}

			.conact-btn {
				button[disabled] {
					cursor: not-allowed;
					opacity: 0.5 !important;
					background: #c9c9c9 !important;
					border-color: #c9c9c9 !important;
					color: #505050 !important;
				}
			}

			.header {
				h4 {
					margin: 0;
					padding: 12px 0;
				}
			}
		}

		.asd__wrapper {
			top: 75px !important;
		}

		.checkbox {
			display: flex;
			align-items: center;

			@media(max-width: 767px) {
				margin-bottom: 15px;
			}

			label {
				margin: 0 5px 0 0 !important;
			}
		}
	}
}