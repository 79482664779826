/*@font-face {
	font-family: 'Sedgwick Ave';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/sedgwick-ave-v10-latin-regular.woff2') format('woff2'),
url('RESOURCE/fonts/sedgwick-ave-v10-latin-regular.woff') format('woff');
font-display: swap;
}

*/

/* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v27-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/open-sans-v27-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v27-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/open-sans-v27-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/open-sans-v27-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/open-sans-v27-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Marydale';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/Marydale.woff2') format('woff2'),
		url('RESOURCE/fonts/Marydale.ttf') format('ttf');
	font-display: swap;
}