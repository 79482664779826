.angebote-page-layout-v1 {
	padding-top: var(--page-margin-top);

	.blogcontent {
		margin-bottom: 30px;
	}

	.img-thumbnail {
		padding: 0;
		border: none;
		border-radius: 0;
	}

	.bl-img {
		img {
			height: 450px;
			object-fit: cover;
			width: 100%;

			@media (max-width: 1199px) {
				height: 300px;
			}

			@media (max-width: 767px) {
				height: 350px;
			}
		}
	}

	.btn.btn-primary {
		position: absolute;
		bottom: 60px;
		right: 30px;

		@media (max-width: 767px) {
			position: static;
		}
	}

	.lt-bl-cont {
		padding: 30px;
		background: #f4f4f4;
		padding-bottom: 90px;
		height: 365px;
		overflow: hidden;

		@media (max-width: 1199px) {
			height: 440px;
		}

		@media (max-width: 991px) {
			height: 475px;
		}

		@media (max-width: 767px) {
			height: auto;
			padding-bottom: 30px;
		}

		h4 {
			font-size: 22px;
		}

		p {}
	}
}