.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	.facility-image {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: 767px) {
			width: 100%;
		}

		iframe {
			margin: 0 20px 0 0;

		}

		img {
			width: 100%;
			max-width: 150px;
			height: auto;
			margin: 0 10px 0 0;

			@media (max-width: 767px) {
				margin: 0 0 15px 0;
			}
		}

		h1 {
			font-family: Marydale;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: normal;

			@media (max-width: 767px) {
				width: 100%;
				text-align: left;
			}
		}
	}

}