.footer-v3 {



	.main-footer {
		overflow: hidden;
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;
		position: relative;

		.custom-row {
			max-width: 1150px;
			margin: 0 auto;
		}


		@media (min-width:992px) {
			overflow: hidden;
		}

		.row {
			&.row-logos {
				justify-content: space-around;
			}
		}

		.logo-cols {
			position: relative;

			padding-right: 15px;
			padding-left: 15px;


			@media (min-width:651px) {
				-webkit-box-flex: 0;
				flex: 0 0 20%;
				max-width: 20%;
			}



			@media (min-width:1200px) {
				-webkit-box-flex: 0;
				flex: 0 0 20%;
				max-width: 20%;
			}

			@media (max-width:650px) {
				-webkit-box-flex: 0;
				flex: 0 0 33.33%;
				max-width: 33.33%;
			}
		}

		.headline {
			font-size: 16px;
			color: var(--color-secondary);
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 1px;

			&.logo-title {
				padding-left: 7px;

				@media (max-width:767px) {
					padding-left: 0;
					text-align: center;
				}
			}
		}


		.logo {
			width: 980px;
			height: auto;
			margin-top: -30px;
			/*padding-bottom: 20px;*/
		}

		.footer-links {
			z-index: 2;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--color-primary);

				}
			}

		}





		.btn-primary {

			.fa {
				color: var(--color-white);
			}
		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
		font-size: 0.8rem;

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				color: var(--footer-copyright-font-color);
				text-decoration: underline;
			}
		}
	}

	.marker {
		opacity: .15;
		width: 1200px;
		height: auto;
		margin-top: -250px;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		position: absolute;
	}

	.logos {
		@media (min-width:992px) {
			margin-top: -26px;
		}
	}

	.facility-logo {
		max-width: 100%;
		max-height: 55px;

		@media (max-width:992px) {
			max-width: 60%;
		}

		@media (max-width:650px) {
			max-width: 80%;
		}
	}

}

.paymentmethods {
	width: 100%;
	text-align: right;
	margin-top: 30px;
	padding-right: 50px;

	@media (max-width:767px) {
		text-align: center;
	}

	.fa {
		color: var(--color-primary) !important;
		font-size: 2.3rem;
		padding-left: 10px;

	}
}