.eigentuemer {
	padding-top: var(--page-margin-top);
	padding-bottom: var(--page-padding-bottom);

	.container {
		/*max-width: 1056px;*/

	}

	.marydale {
		font-family: 'Marydale';
		font-size: 2.1rem !important;
		margin-top: 50px;
	}

	.justify {
		p {
			text-align: justify;
			text-justify: inter-word;
		}
	}

	.quadrat {
		padding: 5px;
		float: right;
		margin-left: 10px;
		background-color: #fff;

		img {
			width: 70px;
			filter: invert(100%);
		}
	}

	.picture {
		margin-bottom: 40px;

		@media (min-width: 1500px) {
			padding-left: 5vw;
		}

		@media (max-width: 1499px) {
			padding-left: 20px;
		}
	}

	.clear {
		clear: right;
	}
}