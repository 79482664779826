.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		color: var(--color-grey-dark);

	}

	.weather-component {
		display: flex;
		justify-content: space-between;

		@media (max-width: 991px) {
			flex-wrap: wrap;
		}

		@media (min-width: 992px) {
			width: 75%;
			flex-direction: column;

		}

		@media (max-width: 767px) {
			justify-content: center;
		}

		.weather-component-right {
			padding: 7px 0 0 0;
			text-align: right;
			width: 70%;
			margin-left: -23px;

			@media (max-width: 1199px) {
				margin-left: -18px;
			}

			@media (max-width:767px) {
				margin-left: 0;
			}
		}

		.region-name {
			@media (max-width: 991px) {
				width: 100%;
			}
		}
	}

	.heading1 {
		padding-bottom: 0px;
		line-height: 45px;


		/*	@media (min-width: 992px) {
			width: 50%;

		}*/
	}

	.headline-sm {
		color: var(--color-primary);
		font-size: 24px;
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
		pointer-events: none;
	}

	.unit-description-v2 {
		text-align: justify;
		margin-bottom: 60px;

		@media (min-width: 993px) {
			/*width: 50%;*/
		}

		@media (max-width: 992px) {
			padding-right: 15px;

		}
	}

	.matterport {
		margin: 0 15px;
		height: 620px;
		padding-right: 30px;

		@media (min-width: 1200px) {
			height: 720px;
		}

		@media (min-width: 1600px) {
			height: 800px;
		}

		@media (min-width: 2000px) {
			height: 900px;
		}

		@media (max-width: 992px) {
			height: 414px;
		}

		@media (max-width: 768px) {
			height: 290px;
		}

		@media (max-width: 560px) {
			height: 340px;
		}

	}

	.fs-gallery-col {
		.v-img-gallery {
			.img-block {
				height: 300px;
				margin-bottom: 30px;

				@media (max-width:992px) {
					height: 240px;
				}
			}

			.img-thumbnail {

				border: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 0;
			}
		}
	}

	.fs-gallery-bl {
		.v-img-gallery {
			margin: 0 -5px;

			&.image-gallery {
				a {
					&:first-child {
						max-width: 70%;
						width: 100%;
						padding: 5px;
						float: right;
						height: 500px;
						display: block;

						@media (max-width: 767px) {
							max-width: 100%;
							height: 300px;
						}
					}
				}
			}


			a {
				display: none;
				padding: 2px;
				height: 175px;

				@media (max-width: 767px) {
					padding: 2px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&:nth-child(1) {
					width: 50%;
					float: left;
					display: block;
					height: 350px;
					overflow: hidden;

					@media (max-width: 1199px) {
						height: 250px;
					}

					@media (max-width: 767px) {
						width: 100%;
						height: 270px;
					}
				}

				&:nth-child(2) {
					clear: both;

					@media (max-width: 767px) {
						clear: none;
						height: 130px;
					}
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					display: block;
					width: 25%;
					float: left;
					clear: none;

					@media (max-width: 1199px) {
						height: 125px;
					}

					@media (max-width: 767px) {
						width: 50%;
						height: 140px;
					}
				}


				&:nth-child(5) {
					position: relative;

					&:before {
						content: "+ mehr";
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						bottom: 0;
						text-align: center;
						width: 100%;
						color: #fff;
						font-weight: 600;
						font-size: var(--font-size-md);
						margin-top: -10px;
						z-index: 1;
						padding: 0 10px;
					}

					&:after {
						content: "";
						position: absolute;
						left: 2px;
						right: 2px;
						top: 2px;
						bottom: 2px;
						background: rgba(0, 0, 0, .5);
						z-index: 0;

						@media (max-width: 767px) {
							top: 1px;
							bottom: 1px;
						}
					}

				}


			}


		}
	}




}